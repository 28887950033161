import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, 10.000000)">
      <g transform="translate(11.000000, 5.000000)">
      <path d="M18.4141 47.1953L28.8828 14.7344H36.2656L21.1094 57H15.6016L0.289062 14.7344H7.67188L18.4141 47.1953ZM30.7797 47.0391L47.0297 0.125H55.2328L34.1391 57H27.4984L6.44375 0.125H14.6078L30.7797 47.0391Z" fill="currentColor"/>
      </g>
    </g>
  </svg>
);

export default IconLogo;
