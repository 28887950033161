import React from 'react';
import { motion } from 'framer-motion';

// Word wrapper
const Wrapper = props =>
  // We'll do this to prevent wrapping of words using CSS
  // eslint-disable-next-line react/prop-types
  <span className="word-wrapper">{props.children}</span>
;

// Map API "type" vaules to JSX tag names
const tagMap = {
  p: 'p',
  h1: 'h1',
  h2: 'h2',
};

// AnimatedCharacters
// Handles the deconstruction of each word and character to setup for the
// individual character animations
const AnimatedCharacters = props => {
  // Framer Motion variant object, for controlling animation
  const item = {
    hidden: {
      y: '200%',
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 },
    },
    visible: {
      y: 0,
      color: 'var(--lightest-slate)',
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 1.5 },
    },
  };

  //  Split each word of props.text into an array
  // eslint-disable-next-line react/prop-types
  const splitWords = props.text.split(' ');

  // Create storage array
  const words = [];

  // Push each word into words array
  for (const [, item] of splitWords.entries()) {
    words.push(item.split(''));
  }

  // Add a space ("\u00A0") to the end of each word
  words.map(word => word.push('\u00A0'));

  // Get the tag name from tagMap
  // eslint-disable-next-line react/prop-types
  const Tag = tagMap[props.type];

  return (
    <Tag className="big-heading">
      {words.map((word, index) => (
        // Wrap each word in the Wrapper component
        <Wrapper key={index}>
          {words[index].flat().map((element, index) => (
            <span
              style={{
                overflow: 'hidden',
                display: 'inline-block',
              }}
              key={index}
            >
              <motion.span
                style={{ display: 'inline-block' }}
                variants={item}
              >
                {element}
              </motion.span>
            </span>
          ))}
        </Wrapper>
      ))}
    </Tag>
  );
};

export default AnimatedCharacters;
