import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled, { keyframes } from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';
import { motion } from 'framer-motion';
import AnimatedText from './AnimatedText';
// import { email } from '@config';


const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 0;

  @media (max-width: 480px) and (min-height: 700px) {
    padding-bottom: 10vh;
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 10px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const blinkTextCursor = keyframes`
  from {border-right-color: rgba(0, 0, 0, .75);}
  to {border-right-color: transparent}
`;

const TextCursor = styled.span`
  border-right: 2px solid rgba(0, 0, 0, .75);
  padding-right: 2px;
  display: inline;
  animation: ${blinkTextCursor} 0.7s steps(44) infinite normal;
`;

const Container = styled.p`
  opacity: 0.7;
  font-weight: 300;
  display: inline-block;
  margin: 0;
`;

function TypeWriter({ value }) {
  const [text, setText] = useState('');

  const typeWriter = (text, i = 0) => {
    // make a random typing delay for each character
    let randomDelay = Math.floor(Math.random() * (100 - 30 + 1)) + 100;
    if (i < value.length) {
      // make an initial delay if i is 0
      randomDelay = i === 0 ? 1000 : randomDelay;
      setText(text.slice(0, i + 1));
      setTimeout(() => {
        typeWriter(text, i + 1);
      }, randomDelay);
    }
  };

  useEffect(() => {
    typeWriter(value);
  }, []);

  return (
    <h2 className="big-heading">
      {text}
      <TextCursor />
    </h2>
  );
}

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const placeholderText = [
    { type: 'h1', text: 'hi, my name is' },
    { type: 'h2', text: 'Victor Vannara' },
  ];

  const container = {
    visible: {
      transition: {
        staggerChildren: 0.02,
      },
    },
  };

  const one = <h1>👋 hi, my name is</h1>;
  // const two = <h2 className="big-heading">Victor Vannara</h2>;

  const two = <motion.div
    className="App"
    initial="hidden"
    animate="visible"
    // animate={'visible' : 'hidden'}
    variants={container}
  >
    <div className="container">
      {placeholderText.map((item, index) => <AnimatedText {...item} key={index} />)}
    </div>
  </motion.div>;

  // const three = <h3 className="big-heading"></h3>;
  // const four = (
  //   <>
  //     <p>
  //     </p>
  //   </>
  // );
  const five = (
    <a
      className="email-link"
      href="/#about">
      ↓ begin
    </a>
  );

  const six = TypeWriter({ value: 'Victor Vannara' });  // <h1>Victor Vannara</h1>;

  const items = [two, five];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${(i + 1) * 50}ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
